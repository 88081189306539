// Path: /src/components/dashboard/Dashboard.jsx
import React from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import '../../styles/Dashboard.css';  // General Dashboard styles

const Dashboard = ({ children }) => {
  return (
    <div className="dashboard-wrapper">
      {/* Sidebar */}
      <Sidebar />

      {/* Main content area */}
      <div className="dashboard-main">
        {/* Header */}
        <Header />

        {/* Main content (children will be the individual sections like Overview, Investments, etc.) */}
        <div className="dashboard-content">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
