// Path: /src/components/dashboard/OverviewSection.jsx
import React from 'react';
import InvestmentChart from '../charts/InvestmentChart';
import ROIChart from '../charts/ROIChart';
import '../../styles/Dashboard.css';

const OverviewSection = () => {
  return (
    <div className="overview-section glassmorphism">
      <h2 className="section-title">Portfolio Overview</h2>
      <div className="overview-charts">
        <InvestmentChart />
        <ROIChart />
      </div>
      <div className="metrics">
        <div className="metric-item">
          <h3>Total Investment</h3>
          <p>$45,000</p>
        </div>
        <div className="metric-item">
          <h3>Total Earnings</h3>
          <p>$12,300</p>
        </div>
        <div className="metric-item">
          <h3>ROI</h3>
          <p>27%</p>
        </div>
      </div>
    </div>
  );
};

export default OverviewSection;
