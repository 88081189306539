import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaSpinner } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

const VerifyEmail = () => {
  // State variables
  const [verificationCode, setVerificationCode] = useState(['', '', '', '']);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [resendTimer, setResendTimer] = useState(30);
  const [canResend, setCanResend] = useState(false);
  const [emailSentMessage, setEmailSentMessage] = useState('');
  
  // Retrieve email from the session or from the route state
  const location = useLocation();
  const email = sessionStorage.getItem('email') || location.state?.email || '';

  // Notify user that a verification email was sent
  useEffect(() => {
    if (email) {
      setEmailSentMessage(`A verification email has been sent to ${email}. Please enter the code below.`);
    } else {
      setEmailSentMessage('Please check your email for a verification code.');
    }
  }, [email]);

  // Handle resend timer
  useEffect(() => {
    if (resendTimer > 0) {
      const timer = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else {
      setCanResend(true);
    }
  }, [resendTimer]);

  // Handle input for the 4-digit code
  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value)) {
      let newCode = [...verificationCode];
      newCode[index] = value;
      setVerificationCode(newCode);
      // Automatically move to the next input if a valid number is entered
      if (index < 3 && value) {
        document.getElementById(`code-${index + 1}`).focus();
      }
    }
    // Handle backspace
    if (value === '' && index > 0) {
      document.getElementById(`code-${index - 1}`).focus();
    }
  };

  const handleResendCode = async () => {
    if (canResend && email) {
      try {
        const response = await fetch('/api/users/resend-code', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email }),
        });

        if (response.ok) {
          setResendTimer(30);  // Reset the timer
          setCanResend(false);
          setErrorMessage('');
        } else {
          const data = await response.json();
          setErrorMessage(data.message || 'Failed to resend the code. Please try again.');
        }
      } catch (error) {
        setErrorMessage('An error occurred. Please try again later.');
      }
    } else {
      setErrorMessage('Unable to resend code. Please try again later.');
    }
  };

  const handleVerification = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage('');
    setSuccessMessage('');

    const code = verificationCode.join('');

    if (!email) {
      setErrorMessage('Email not found. Please register again.');
      setLoading(false);
      return;
    }

    try {
      const response = await fetch('/api/users/verify', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, verificationCode: code }),
      });

      const data = await response.json();
      if (response.ok) {
        setSuccessMessage('Email verified successfully! Redirecting to your dashboard...');
        setTimeout(() => {
          window.location.href = '/dashboard';
        }, 2000);
      } else {
        setErrorMessage(data.message || 'Invalid code. Please try again.');
      }
    } catch (error) {
      setErrorMessage('An error occurred. Please try again later.');
    }

    setLoading(false);
  };

  return (
    <motion.div
      className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-50 to-blue-100"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <div className="glassmorphism-container bg-white p-8 rounded-lg shadow-lg max-w-md w-full relative">
        <h2 className="text-2xl font-semibold text-center mb-6">Verify Your Email</h2>

        {/* Display email sent notification */}
        {emailSentMessage && (
          <motion.p className="text-blue-500 text-center mb-4" initial={{ scale: 0 }} animate={{ scale: 1 }}>
            {emailSentMessage}
          </motion.p>
        )}

        {/* Display error/success messages */}
        {errorMessage && (
          <motion.p className="text-red-500 text-center mb-4" initial={{ scale: 0 }} animate={{ scale: 1 }}>
            {errorMessage}
          </motion.p>
        )}
        {successMessage && (
          <motion.p className="text-green-500 text-center mb-4" initial={{ scale: 0 }} animate={{ scale: 1 }}>
            {successMessage}
          </motion.p>
        )}

        {!successMessage && (
          <form onSubmit={handleVerification}>
            {/* 4-digit verification code input */}
            <motion.div className="grid grid-cols-4 gap-4 mb-6" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              {verificationCode.map((digit, index) => (
                <motion.input
                  key={index}
                  type="text"
                  maxLength="1"
                  value={digit}
                  onChange={(e) => handleChange(e, index)}
                  id={`code-${index}`}
                  className={`w-full p-4 text-center text-xl border ${
                    digit ? 'border-green-500' : 'border-gray-300'
                  } rounded-lg focus:ring-2 focus:ring-blue-300 transition-all`}
                />
              ))}
            </motion.div>

            {/* Verify button with spinner animation */}
            <motion.button
              type="submit"
              disabled={loading || verificationCode.includes('')}
              className={`w-full py-3 px-4 bg-blue-600 text-white rounded-lg shadow-md transition-all ${
                loading ? 'opacity-50' : 'hover:bg-blue-700'
              }`}
            >
              {loading ? (
                <FaSpinner className="animate-spin inline-block" />
              ) : (
                'Verify'
              )}
            </motion.button>
          </form>
        )}

        {/* Resend code section */}
        <motion.div className="mt-6 text-center" initial={{ scale: 0 }} animate={{ scale: 1 }}>
          <p className="text-gray-500">Didn't receive the code?</p>
          <button
            onClick={handleResendCode}
            disabled={!canResend}
            className={`text-blue-500 mt-2 ${!canResend ? 'opacity-50' : 'hover:underline'}`}
          >
            Resend Code {canResend ? '' : `in ${resendTimer}s`}
          </button>
        </motion.div>

        {/* Progress bar */}
        <motion.div className="absolute top-0 left-0 w-full h-1 bg-gray-200">
          <motion.div
            className="h-full bg-blue-500"
            style={{ width: `${(verificationCode.filter((digit) => digit !== '').length / 4) * 100}%` }}
            transition={{ duration: 0.3 }}
          />
        </motion.div>
      </div>
    </motion.div>
  );
};

export default VerifyEmail;
