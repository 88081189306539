// Path: /src/services/mockDataService.js

const getInvestments = () => {
    return [
      { id: 1, name: 'Luxury Apartment', amount: 10000, roi: 15, duration: 12 },
      { id: 2, name: 'Office Building', amount: 25000, roi: 10, duration: 24 },
      // More mock investments
    ];
  };
  
  const getTransactions = () => {
    return [
      { id: 'TX001', amount: 5000, date: '2023-09-28', status: 'Completed' },
      { id: 'TX002', amount: 3000, date: '2023-10-05', status: 'Pending' },
      // More mock transactions
    ];
  };
  
  const getReferrals = () => {
    return [
      { id: 1, code: 'REF1234', bonus: 100 },
      { id: 2, code: 'REF5678', bonus: 200 },
      // More mock referrals
    ];
  };
  
  const getMarketActivities = () => {
    return [
      { id: 1, message: 'Luxury Apartment Pool updated', date: '2023-10-01' },
      { id: 2, message: 'New Investment in Office Building', date: '2023-10-03' },
      // More mock market activities
    ];
  };
  
  const getInvestmentChartData = () => {
    return {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
      values: [10000, 12000, 14000, 16000, 18000, 20000],
    };
  };
  
  const getROIChartData = () => {
    return {
      labels: ['Luxury Apartment', 'Office Building', 'Retail Space'],
      values: [40, 35, 25],
    };
  };
  
  export default {
    getInvestments,
    getTransactions,
    getReferrals,
    getMarketActivities,
    getInvestmentChartData,
    getROIChartData,
  };
  