import React from 'react';

const Home = () => {
  return (
    <div className="bg-gradient-to-r from-blue-50 to-blue-100 min-h-screen">
      <section className="text-center py-20">
        <h1 className="text-5xl font-bold text-blue-900 mb-4">Majid Label Real Estate</h1>
        <p className="text-xl text-gray-700 mb-8">
          Discover and invest in premium properties worldwide.
        </p>
        <button className="bg-blue-600 text-white px-6 py-3 rounded-full hover:bg-blue-800">
          Explore Properties
        </button>
      </section>
    </div>
  );
};

export default Home;
