// src/components/Header.jsx
import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className="bg-white shadow-lg py-4 sticky top-0 z-50">
      <div className="container mx-auto flex justify-between items-center px-6">
        {/* Logo */}
        <div className="text-3xl font-bold text-blue-900">
          <Link to="/">Majid Label</Link>  {/* Link to Home */}
        </div>
        
        {/* Navigation Links */}
        <nav className="space-x-8 hidden md:flex">
          <Link to="/" className="text-lg text-blue-700 hover:text-blue-900">Home</Link>
          <Link to="/properties" className="text-lg text-blue-700 hover:text-blue-900">Properties</Link>
          <Link to="/about" className="text-lg text-blue-700 hover:text-blue-900">About</Link>
          <Link to="/contact" className="text-lg text-blue-700 hover:text-blue-900">Contact</Link>
        </nav>

        {/* Login and Sign Up Buttons */}
        <div className="flex space-x-4">
          <Link to="/login">  {/* Link to Login page */}
            <button className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-800">Login</button>
          </Link>
          <Link to="/signup">  {/* Link to Sign Up page */}
            <button className="bg-white text-blue-600 border border-blue-600 px-4 py-2 rounded-lg hover:bg-blue-100">Sign Up</button>
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Header;
