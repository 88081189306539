import React, { useState } from 'react';
import { FaBell, FaCog, FaSearch, FaUserCircle } from 'react-icons/fa';
import '../../styles/Header.css';

const Header = ({ toggleDarkMode }) => {
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);

  const handleNotificationClick = () => {
    setNotificationsOpen(!notificationsOpen);
  };

  const handleProfileClick = () => {
    setProfileOpen(!profileOpen);
  };

  return (
    <header className="dashboard-header">
      {/* Branding Section */}
      <div className="branding">
        <h1>Real Estate Platform</h1>
      </div>

      {/* Search Bar */}
      <div className="search-bar">
        <input type="text" placeholder="Search investments, properties, etc..." />
        <FaSearch className="search-icon" />
      </div>

      {/* Right Section: Notifications, Settings, and Profile */}
      <div className="header-right">
        {/* Notification Bell with Badge */}
        <div className="notification-bell" onClick={handleNotificationClick}>
          <FaBell className="icon" />
          <span className="notification-count">3</span> {/* Badge Counter */}
          {notificationsOpen && (
            <div className="notification-dropdown">
              <ul>
                <li className="unread">New Investment Opportunity in Pool A</li>
                <li>Market Update: Property XYZ</li>
                <li>System Alert: Scheduled Maintenance</li>
              </ul>
            </div>
          )}
        </div>

        {/* Dark Mode Toggle */}
        <div className="dark-mode-toggle" onClick={toggleDarkMode}>
          <FaCog className="icon" title="Toggle Dark Mode" />
        </div>

        {/* Profile Section */}
        <div className="profile-section" onClick={handleProfileClick}>
          <FaUserCircle className="icon profile-icon" />
          {profileOpen && (
            <div className="profile-dropdown">
              <ul>
                <li>View Profile</li>
                <li>Account Settings</li>
                <li>Logout</li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
