// Path: /src/components/charts/ROIChart.jsx
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import mockDataService from '../../services/mockDataService';

// Register the necessary components for Doughnut charts
ChartJS.register(
  ArcElement,  // Required for doughnut/pie charts
  Tooltip,
  Legend
);

const ROIChart = () => {
  const data = mockDataService.getROIChartData();

  const chartData = {
    labels: data.labels,
    datasets: [
      {
        label: 'ROI Distribution',
        data: data.values,
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
      },
    ],
  };

  return (
    <div className="roi-chart">
      <Doughnut data={chartData} />
    </div>
  );
};

export default ROIChart;
