// Path: /src/components/charts/InvestmentChart.jsx
import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import mockDataService from '../../services/mockDataService';

// Register the necessary components for Line charts
ChartJS.register(
  LineElement,
  CategoryScale,  // Registering category scale
  LinearScale,    // For linear axis scaling
  PointElement,   // For points in Line charts
  Title,
  Tooltip,
  Legend
);

const InvestmentChart = () => {
  const data = mockDataService.getInvestmentChartData();

  const chartData = {
    labels: data.labels,
    datasets: [
      {
        label: 'Investment Growth',
        data: data.values,
        borderColor: '#4CAF50',
        backgroundColor: 'rgba(76, 175, 80, 0.1)',
        fill: true,
      },
    ],
  };

  return (
    <div className="investment-chart">
      <Line data={chartData} />
    </div>
  );
};

export default InvestmentChart;
