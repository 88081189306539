import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-12">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-4 gap-8 px-6">
        {/* About Section */}
        <div>
          <h3 className="font-bold text-xl mb-4 text-white">About Majid Label</h3>
          <p className="text-gray-400">
            Discover exclusive real estate opportunities with Majid Label. We offer luxurious properties that elevate your lifestyle.
          </p>
        </div>

        {/* Quick Links */}
        <div>
          <h3 className="font-bold text-xl mb-4 text-white">Quick Links</h3>
          <ul>
            <li className="mb-2"><a href="/properties" className="hover:underline text-gray-400">Properties</a></li>
            <li className="mb-2"><a href="/contact" className="hover:underline text-gray-400">Contact Us</a></li>
            <li className="mb-2"><a href="/login" className="hover:underline text-gray-400">Login</a></li>
            <li className="mb-2"><a href="/signup" className="hover:underline text-gray-400">Sign Up</a></li>
          </ul>
        </div>

        {/* Legal Links */}
        <div>
          <h3 className="font-bold text-xl mb-4 text-white">Legal</h3>
          <ul>
            <li className="mb-2"><a href="/terms" className="hover:underline text-gray-400">Terms of Service</a></li>
            <li className="mb-2"><a href="/privacy" className="hover:underline text-gray-400">Privacy Policy</a></li>
          </ul>
        </div>

        {/* Social Media Links */}
        <div>
          <h3 className="font-bold text-xl mb-4 text-white">Connect with Us</h3>
          <div className="flex space-x-4">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="hover:text-gray-400 text-gray-400">
              <FaFacebookF size={24} />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="hover:text-gray-400 text-gray-400">
              <FaTwitter size={24} />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="hover:text-gray-400 text-gray-400">
              <FaInstagram size={24} />
            </a>
          </div>
        </div>
      </div>
      
      {/* Footer Bottom */}
      <div className="border-t border-gray-800 mt-8 pt-6 text-center text-gray-500 text-sm">
        <p>&copy; {new Date().getFullYear()} Majid Label. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
