// Path: /src/components/dashboard/InvestmentSection.jsx
import React, { useState, useEffect } from 'react';
import mockDataService from '../../services/mockDataService'; // Mock service for data
import '../../styles/Dashboard.css';

const InvestmentSection = () => {
  const [investments, setInvestments] = useState([]);

  useEffect(() => {
    const fetchInvestments = async () => {
      const data = await mockDataService.getInvestments();
      setInvestments(data);
    };
    fetchInvestments();
  }, []);

  return (
    <div className="investment-section glassmorphism">
      <h2 className="section-title">Your Investments</h2>
      <div className="investment-list">
        {investments.map((investment) => (
          <div key={investment.id} className="investment-item">
            <h3>{investment.name}</h3>
            <p>Amount: ${investment.amount}</p>
            <p>ROI: {investment.roi}%</p>
            <p>Duration: {investment.duration} months</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InvestmentSection;
