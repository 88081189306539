import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import {
  FaHome, FaChartLine, FaMoneyCheckAlt, FaBell, FaCog, FaSignOutAlt, FaBars, FaTimes, FaMedal, FaMoon
} from 'react-icons/fa';

const userImage = '/assets/images/user.png'; // Assuming this is the user image path

const Sidebar = ({ isDarkMode, toggleDarkMode }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth <= 768) {
        setIsCollapsed(true); // Collapse on mobile (width <= 768px)
      } else {
        setIsCollapsed(false); // Expand on larger screens
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize(); // Trigger on initial load
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const navItems = [
    { name: 'Overview', icon: FaHome, path: '/dashboard/overview' },
    { name: 'Investments', icon: FaChartLine, path: '/dashboard/investments' },
    { name: 'Transactions', icon: FaMoneyCheckAlt, path: '/dashboard/transactions' },
    { name: 'Market Activity', icon: FaChartLine, path: '/dashboard/market-activity' },
    { name: 'Referrals', icon: FaBell, path: '/dashboard/referrals' },
    { name: 'Notifications', icon: FaBell, path: '/dashboard/notifications' },
    { name: 'Settings', icon: FaCog, path: '/dashboard/settings' },
  ];

  const sidebarWidth = windowWidth <= 768 ? (isCollapsed ? 'w-16' : 'w-[55vw]') : (isCollapsed ? 'w-16' : 'w-72');

  return (
    <aside
      className={`flex flex-col h-screen transition-all duration-500 ease-in-out ${isCollapsed ? 'w-16' : 'w-72'} ${isDarkMode ? 'bg-gray-900 text-gray-100' : 'bg-gray-50 text-gray-800'} shadow-lg`}
    >
      {/* Sidebar Toggle Button */}
      <div className="flex justify-end p-4">
        <button
          onClick={toggleSidebar}
          className={`text-gray-500 transition-transform duration-500 ${isCollapsed ? '' : 'transform translate-x-8'}`}
        >
          {isCollapsed ? <FaBars className="text-2xl" /> : <FaTimes className="text-2xl" />}
        </button>
      </div>

      {/* User Profile Section */}
      <div className={`flex flex-col items-center justify-center transition-all duration-700 ${isCollapsed ? 'h-20' : 'h-40'} bg-blue-600`}>
        <img
          src={userImage}
          alt="User Profile"
          className={`rounded-full border ${isCollapsed ? 'w-8 h-8 border-2' : 'w-16 h-16 border-4'} transition-all duration-300`}
        />
        {!isCollapsed && (
          <div className="mt-3 text-center">
            <p
              className={`text-lg font-semibold text-white transition-all duration-500 ${isCollapsed ? 'opacity-0' : 'opacity-100'}`}
              style={{ transitionDelay: '0.4s' }}
            >
              John Doe
            </p>
            <div
              className={`flex items-center justify-center mt-2 transition-all duration-500 ${isCollapsed ? 'opacity-0' : 'opacity-100'}`}
              style={{ transitionDelay: '0.4s' }}
            >
              <FaMedal className="text-yellow-400" />
              <span className="ml-2 text-white">Gold Investor</span>
            </div>
          </div>
        )}
      </div>

      {/* Navigation Links */}
      <nav className="flex flex-col p-0 space-y-0">
        {navItems.map((item, index) => (
          <NavLink
            key={index}
            to={item.path}
            className={`flex items-center p-2 group hover:bg-blue-500 transition-all duration-300 ${isDarkMode ? 'text-gray-200' : 'text-gray-600'}`}
            activeClassName="bg-blue-600 text-white"
            style={{ minHeight: '48px', margin: '0' }}
          >
            <item.icon
              className="text-2xl transition-transform duration-500 group-hover:text-white"
              style={{ minWidth: '40px' }}
            />
            <span
              className={`ml-4 text-md font-medium transition-opacity duration-500 ${isCollapsed ? 'opacity-0' : 'opacity-100'}`}
              style={{ transitionDelay: '0.4s' }}
            >
              {item.name}
            </span>
          </NavLink>
        ))}
      </nav>

      {/* Dark Mode Toggle */}
      <div className="p-4 mt-auto transition-all duration-400 ease-in-out" style={{ marginBottom: '0.5rem' }}>
        <button onClick={toggleDarkMode} className="w-full flex items-center justify-center space-x-3 p-2 rounded-md">
          <FaMoon className="text-black" />
          {!isCollapsed && (
            <span
              className="text-black transition-opacity duration-500"
              style={{ transitionDelay: '0.4s' }}
            >
              Dark Mode
            </span>
          )}
        </button>
      </div>

      {/* Logout Button in Footer */}
      <div className="p-4 mt-4 border-t transition-all duration-400 ease-in-out" style={{ marginBottom: '1rem' }}>
        <NavLink to="/logout" className="flex items-center justify-center space-x-3 text-red-600 hover:text-red-800">
          <FaSignOutAlt className="text-xl" />
          {!isCollapsed && <span className="font-medium">Logout</span>}
        </NavLink>
      </div>
    </aside>
  );
};

export default Sidebar;
