import React, { useState } from 'react';
import { FaGoogle, FaUser, FaEnvelope, FaLock, FaRegSmile, FaEye, FaEyeSlash, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { HiOutlineCheckCircle } from 'react-icons/hi';
import confetti from 'canvas-confetti';

// Importing Google Fonts
import "@fontsource/poppins"; // Modern typography from Google Fonts

const SignUp = () => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState('');
  const [formProgress, setFormProgress] = useState(0);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  // Track if password meets the guidelines
  const [isPasswordLongEnough, setIsPasswordLongEnough] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);

  const handleTermsToggle = () => {
    setIsTermsAccepted(!isTermsAccepted);
  };

  const navigate = useNavigate();

  // Function to handle password change and strength evaluation
  const handlePasswordChange = (e) => {
    const pwd = e.target.value;
    setPassword(pwd);
    evaluatePasswordStrength(pwd);
    calculateProgress();
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    calculateProgress();
  };

  const handleFullNameChange = (e) => {
    setFullName(e.target.value);
    calculateProgress();
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    calculateProgress();
  };

  // Password strength evaluation function with feedback
  const evaluatePasswordStrength = (pwd) => {
    const strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
    const mediumRegex = /^(?=.*[a-z])(?=.*\d)(?=.*[\W_]).{6,}$/;

    // Track each guideline's completion
    setIsPasswordLongEnough(pwd.length >= 8);
    setHasUpperCase(/[A-Z]/.test(pwd));
    setHasNumber(/\d/.test(pwd));
    setHasSpecialChar(/[\W_]/.test(pwd));

    // Set the password strength based on regex match
    if (strongRegex.test(pwd)) {
      setPasswordStrength('strong');
    } else if (mediumRegex.test(pwd)) {
      setPasswordStrength('medium');
    } else {
      setPasswordStrength('weak');
    }
  };

  // Function to calculate form completion progress
  const calculateProgress = () => {
    let progress = 0;
    if (fullName) progress += 25;
    if (email) progress += 25;
    if (password) progress += 25;
    if (confirmPassword) progress += 25;
    setFormProgress(progress);
  };

  // Show/hide password toggle functions
  const toggleShowPassword = () => setShowPassword(!showPassword);
  const toggleShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  // Handle form submission
  const handleSignUp = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
  
    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match');
      setLoading(false);
      return;
    }
  
    if (!isTermsAccepted) {
      setErrorMessage('You must accept the terms and conditions');
      setLoading(false);
      return;
    }
  
    try {
      const response = await fetch('/api/users/register', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password, fullName, referralCode }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        setSuccessMessage('Account created successfully! Redirecting...');
  
        // Store the email in local storage/session storage
        sessionStorage.setItem('email', data.email);
  
        setIsSubmitted(true);
        // Trigger confetti animation on success
        confettiAnimation();
  
        // Redirect to the verify page with email in state or session storage
        setTimeout(() => navigate(data.redirectTo), 2000);
      } else {
        setErrorMessage(data.message || 'Registration failed. Please try again.');
      }
    } catch (error) {
      setErrorMessage('An error occurred. Please try again later.');
    }
  
    setLoading(false);
  };
  

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };
  

  // Confetti animation for successful registration
  const confettiAnimation = () => {
    const end = Date.now() + 1000;
    (function frame() {
      confetti({
        particleCount: 3,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
      });
      confetti({
        particleCount: 3,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
      });
      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    })();
  };

  return (
    <div className="font-poppins bg-gradient-to-r from-blue-50 to-blue-100 min-h-screen flex items-center justify-center relative">
      <motion.div
        className="bg-white bg-opacity-70 backdrop-blur-lg rounded-lg shadow-lg p-8 max-w-lg w-full relative transition-all duration-500"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        {/* Form Progress Bar */}
        <div className="h-2 bg-gray-200 rounded-full overflow-hidden mb-6 shadow-sm">
          <div
            className={`h-full rounded-full transition-all duration-500 ${
              formProgress === 100 ? 'bg-green-500' : 'bg-blue-500'
            }`}
            style={{ width: `${formProgress}%` }}
          ></div>
        </div>

        {/* Success and Error Messages */}
        {errorMessage && (
          <motion.div className="text-red-500 text-center mb-4" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            {errorMessage}
          </motion.div>
        )}

        {successMessage && (
          <motion.div className="text-green-500 text-center mb-4 flex items-center justify-center" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <HiOutlineCheckCircle className="text-green-500 w-6 h-6 mr-2" />
            {successMessage}
          </motion.div>
        )}

        {!isSubmitted && (
          <form onSubmit={handleSignUp}>
            {/* Full Name Input */}
            <div className="mb-4 relative">
              <FaUser className="absolute left-3 top-3 text-gray-400" />
              <input
                type="text"
                value={fullName}
                onChange={handleFullNameChange}
                className="w-full px-10 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300 transition-all duration-300"
                placeholder="Full Name"
                required
              />
            </div>

            {/* Email Input */}
            <div className="mb-4 relative">
              <FaEnvelope className="absolute left-3 top-3 text-gray-400" />
              <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                className="w-full px-10 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300 transition-all duration-300"
                placeholder="Email"
                required
              />
              {validateEmail(email) ? (
                <FaCheckCircle className="absolute right-3 top-3 text-green-500" />
              ) : email.length > 0 ? (
                <FaTimesCircle className="absolute right-3 top-3 text-red-500" />
              ) : null}
            </div>

            {/* Password Input with Guidelines */}
            <div className="mb-4 relative">
              <FaLock className="absolute left-3 top-3 text-gray-400" />
              <input
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={handlePasswordChange}
                className="w-full px-10 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300 transition-all duration-300"
                placeholder="Password"
                required
              />
              <button
                type="button"
                className="absolute right-3 top-3 text-gray-400 focus:outline-none"
                onClick={toggleShowPassword}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>

              {password && (
                <div className="text-sm mt-2">
                  <div className="relative w-full bg-gray-200 rounded-full h-2">
                    <motion.div
                      className={`h-2 rounded-full transition-all duration-500 ${
                        passwordStrength === 'strong'
                          ? 'bg-green-500'
                          : passwordStrength === 'medium'
                          ? 'bg-yellow-500'
                          : 'bg-red-500'
                      }`}
                      style={{
                        width:
                          passwordStrength === 'strong'
                            ? '100%'
                            : passwordStrength === 'medium'
                            ? '60%'
                            : '30%',
                      }}
                      initial={{ width: 0 }}
                      animate={{ width: passwordStrength === 'strong' ? '100%' : passwordStrength === 'medium' ? '60%' : '30%' }}
                    />
                  </div>
                  <p
                    className={`font-semibold ${
                      passwordStrength === 'strong'
                        ? 'text-green-500'
                        : passwordStrength === 'medium'
                        ? 'text-yellow-500'
                        : 'text-red-500'
                    } mt-2`}
                  >
                    {passwordStrength === 'strong'
                      ? 'Strong password'
                      : passwordStrength === 'medium'
                      ? 'Medium password'
                      : 'Weak password'}
                  </p>
                  <ul className="text-xs mt-2">
                    <li className={`${isPasswordLongEnough ? 'text-green-500' : 'text-red-500'}`}>• At least 8 characters</li>
                    <li className={`${hasUpperCase ? 'text-green-500' : 'text-red-500'}`}>• At least one uppercase letter</li>
                    <li className={`${hasNumber ? 'text-green-500' : 'text-red-500'}`}>• At least one number</li>
                    <li className={`${hasSpecialChar ? 'text-green-500' : 'text-red-500'}`}>• At least one special character (e.g., @, #, $)</li>
                  </ul>
                </div>
              )}
            </div>

            {/* Confirm Password Input with Match Animation */}
            <div className="mb-4 relative">
              <FaLock className="absolute left-3 top-3 text-gray-400" />
              <input
                type={showConfirmPassword ? 'text' : 'password'}
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                className={`w-full px-10 py-3 border ${
                  password === confirmPassword
                    ? 'border-green-300'
                    : confirmPassword
                    ? 'border-red-300'
                    : 'border-gray-300'
                } rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300 transition-all duration-300`}
                placeholder="Confirm Password"
                required
              />
              <button
                type="button"
                className="absolute right-3 top-3 text-gray-400 focus:outline-none"
                onClick={toggleShowConfirmPassword}
              >
                {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
              {confirmPassword && (
                <motion.div
                  className={`absolute right-3 top-12 ${
                    password === confirmPassword ? 'text-green-500' : 'text-red-500'
                  }`}
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ duration: 0.3 }}
                >
                  {password === confirmPassword ? (
                    <FaCheckCircle />
                  ) : (
                    <FaTimesCircle />
                  )}
                </motion.div>
              )}
            </div>

            {/* Referral Code Input */}
            <div className="mb-4 relative">
              <FaRegSmile className="absolute left-3 top-3 text-gray-400" />
              <input
                type="text"
                value={referralCode}
                onChange={(e) => setReferralCode(e.target.value)}
                className="w-full px-10 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300 transition-all duration-300"
                placeholder="Referral Code (optional)"
              />
            </div>

            {/* Terms and Conditions Checkbox */}
            <div className="flex items-center mb-4">
              <input
                type="checkbox"
                checked={isTermsAccepted}
                onChange={handleTermsToggle}
                id="terms"
                className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
              />
              <label htmlFor="terms" className="ml-2 block text-sm text-gray-700">
                I agree to the <a href="/terms" className="text-blue-500 underline">Terms and Conditions</a>
              </label>
            </div>

            {/* Sign Up Button with Loading Animation */}
            <button
              type="submit"
              className={`w-full py-3 px-4 bg-gradient-to-r from-blue-500 to-blue-700 text-white font-semibold rounded-lg shadow-md hover:from-blue-600 hover:to-blue-800 transition-all duration-300 ${
                loading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={loading || !isTermsAccepted}
            >
              {loading ? (
                <motion.div
                  className="inline-block"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  Creating Account...
                </motion.div>
              ) : (
                'Sign Up'
              )}
            </button>
          </form>
        )}

        {/* OR Divider */}
        <div className="my-6 text-center">
          <span className="text-gray-400">OR</span>
        </div>

        {/* Animated Google Button */}
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => window.location.href = 'https://majidlabel.com/auth/google'}
          className="w-full py-2 px-4 border border-gray-300 rounded-lg flex items-center justify-center hover:bg-gray-100 transition-all duration-300"
        >
          <FaGoogle className="text-red-500 mr-2" /> Sign Up with Google
        </motion.button>
      </motion.div>
    </div>
  );
};

export default SignUp;
