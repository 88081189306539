import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header'; // Global Header
import Footer from './components/Footer'; // Global Footer
import Home from './components/Home';
import Login from './components/Login';
import SignUp from './components/SignUp';
import VerifyCode from './components/VerifyEmail';
import Dashboard from './components/dashboard/Dashboard';  // Dashboard wrapper
import OverviewSection from './components/dashboard/OverviewSection';  // Dashboard sections
import InvestmentSection from './components/dashboard/InvestmentSection';  // Dashboard sections

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Routes>
          {/* Non-dashboard pages with global Header and Footer */}
          <Route
            path="/"
            element={
              <>
                <Header /> {/* Global Header */}
                <Home />
                <Footer /> {/* Global Footer */}
              </>
            }
          />
          <Route
            path="/login"
            element={
              <>
                <Header />
                <Login />
                <Footer />
              </>
            }
          />
          <Route
            path="/signup"
            element={
              <>
                <Header />
                <SignUp />
                <Footer />
              </>
            }
          />
          <Route
            path="/verify-code"
            element={
              <>
                <Header />
                <VerifyCode />
                <Footer />
              </>
            }
          />

          {/* Dashboard routes without global Header and Footer */}
          <Route
            path="/dashboard"
            element={
              <Dashboard>
                <OverviewSection /> {/* Default dashboard content */}
              </Dashboard>
            }
          />
          <Route
            path="/dashboard/investments"
            element={
              <Dashboard>
                <InvestmentSection />
              </Dashboard>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
